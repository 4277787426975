<template>
  <CRow class="col-12">
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CCard class="col-4 mr-4 ml-4">
      <CCardHeader>
        <strong>Şikayet Bilgisi</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol class="p-2">
            <CRow >
              <CCol class=" mb-2 ml-5">
                Şikayetçi:
              </CCol>
              <CCol >
                Adam Alister
              </CCol>
            </CRow>
            <c-dropdown-divider/>
            <CRow >
              <CCol class="mb-2 ml-5">
                Rol:
              </CCol>
              <CCol >
                Öğretmen
              </CCol>
            </CRow>
            <c-dropdown-divider/>
            <CRow >
              <CCol class="mb-2 ml-5">
                Şikayet Tarihi
              </CCol>
              <CCol >
                2012/01/01
              </CCol>
            </CRow>
            <c-dropdown-divider/>
            <CRow >
              <CCol class="mb-2 ml-5">
                Şikayet Kategorisi:
              </CCol>
              <CCol >
                Randevu Şikayeti
              </CCol>
            </CRow>
            <c-dropdown-divider/>
            <CRow >
              <CCol class="mb-2 ml-5">
                Durum:
              </CCol>
              <CCol >
                İşlem Yapılmadı
              </CCol>
            </CRow>
            <c-dropdown-divider/>
            <CRow >
              <CCol class="mb-3 ml-5">
                Şikayet Edilen Kişi:
              </CCol>
              <CCol >
                Adam Alister2
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCol class="col-7">
      <CCard>
        <CCardHeader>
          <strong>Şikayet Özeti</strong>
        </CCardHeader>
        <CCardBody>
          <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
            rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit
            amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam
            aliquam quaerat voluptatem."</p>
        </CCardBody>
      </CCard>
      <CRow class="d-flex align-items-center align-self-center">
        <CCol col="4">
          <CSelect
              label="İşlem Durumu"
              vertical
              :options="options"
              placeholder="Tamamlandı Olarak İşaretle"
          />
        </CCol>
        <CCol col="4">
          <CButton @click="warningModal = true" block color="dark">Mesajlaşmayı Gör</CButton>
          <CModal
              title="Randevu Detayları"
              color="Info"
              :show.sync="warningModal"
          >
            <CRow>
              <CCol class="p-2">
                <CRow >
                  <CCol class=" mb-3 ml-5">
                    Planlanan Kişi:
                  </CCol>
                  <CCol >
                    Adam Alister
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow >
                  <CCol class=" ml-5">
                    Randevu Başlangıç
                  </CCol>
                  <CCol >
                    2012/01/01 - 09:15
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow >
                  <CCol class=" ml-5">
                    Randevu Bitiş
                  </CCol>
                  <CCol >
                    2012/01/01 - 10:15
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow >
                  <CCol class=" ml-5">
                    Randevu Süresi
                  </CCol>
                  <CCol >
                    60 Dakika
                  </CCol>
                </CRow>
              </CCol>
            </CRow>

            <template v-slot:footer>
              <CRow class="col-12">
                <CCol>
                  <CButton block color="dark">Pencereyi Kapat</CButton>
                </CCol>
                <CCol>
                  <CButton block color="danger">Randevuyu İptal Et</CButton>
                </CCol>
              </CRow>
            </template>
          </CModal>
        </CCol>
        <CCol col="4">
          <CButton @click="showToast()" block color="info">Değişiklikleri Kaydet</CButton>
        </CCol>
        <CCol col="12" class="mt-4 text-right" >
          <router-link to="/sikayetler" tag="button" role="button" block class="btn btn-secondary">Geri</router-link>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "MessageComplaints",
  data() {
    return {
      warningModal: false,
      toasts: [],
      options: ['Tamamlandı Olarak İşaretle', 'Tamamlanmadı Olarak İşaretle', 'Öylesine İşaretle'],
    }
  },
  methods: {
    showToast: function (text = 'Değişiklikler Başarıyla Kaydedildi', header = 'Bilgi') {
      this.toasts.push({text, header});
    },
    getBadge(role) {
      switch (role) {
        case "Öğretmen":
          return 'success';
        case "İşlem Yapılmadı":
          return 'warning';
        case "Randevu Şikayeti":
          return 'dark';
        default:
          return 'primary';
      }
    },
  }
}
</script>
